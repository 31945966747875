.footer {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 4;
    mix-blend-mode: difference;
    color: #10BEFF;
    bottom: 0;

}

.apps {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 300ms;
}

@media only screen and (max-width: 600px) {
    .smIcon{
        font-size: 18px;
    }
}

@media only screen and (min-width: 600px) {
    .smIcon{
        font-size: 18px;
    }
}

@media only screen and (min-width: 768px) {
    .smIcon > *{
        display: block;
    }
    .smIcon{
        font-size: 21px;
        display: table-cell;
    }
}

@media only screen and (min-width: 992px) {
    .smIcon{
        font-size: 23px;
    }
}

@media only screen and (min-width: 1200px) {
    .smIcon{
        font-size: 25px;
    }
}

.smIcon {
    margin-right: 2rem;
    margin: 10px;
    padding-bottom: 0%;
    height: auto;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.smIcon:hover {
    color: #ffffff;
    transition: .5s;
    transition-duration: .5s;
    transform: scale(1.25);
}

.footerText {
    width: 100%;
    height: auto;
    margin-right: 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.smFooter {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    height: 10vh;
    object-fit: cover;
}
